exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-teacher-js": () => import("./../../../src/pages/become-a-teacher.js" /* webpackChunkName: "component---src-pages-become-a-teacher-js" */),
  "component---src-pages-book-free-trial-js": () => import("./../../../src/pages/book-free-trial.js" /* webpackChunkName: "component---src-pages-book-free-trial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-bharatanatyam-online-js": () => import("./../../../src/pages/learn-bharatanatyam-online.js" /* webpackChunkName: "component---src-pages-learn-bharatanatyam-online-js" */),
  "component---src-pages-learn-hindustani-music-online-js": () => import("./../../../src/pages/learn-hindustani-music-online.js" /* webpackChunkName: "component---src-pages-learn-hindustani-music-online-js" */),
  "component---src-pages-our-teachers-js": () => import("./../../../src/pages/our-teachers.js" /* webpackChunkName: "component---src-pages-our-teachers-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */)
}

